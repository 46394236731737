.header{
  background-color: #FFFFFF;
  color: #1E295D;
  font-size: 36px;
}
.inner-content{
  background-color: #FFFFFF;
  color: #1E295D;
  padding-top: 21px;
  padding-left: 110px !important;
}
.content-icon{
  height: 25px;
  width: 20px;
}
.content-c{
  color: #707070;
  background-color: #FFFFFF;
  padding-top: 21px;
  padding-left: 130px !important;
}