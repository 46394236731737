.widget-content {
    padding: 1rem;
}
.widget-content .widget-content-outer {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}
.widget-content .widget-content-wrapper {
    display: flex;
    flex: 1 1;
    position: relative;
    align-items: center;
}
.widget-content .widget-content-left .widget-heading {
    opacity: .8;
    font-weight: 700;
    font-size: 12px;
}
.widget-content .widget-content-left .widget-subheading {
    opacity: .5;
}
.widget-content .widget-content-right {
    margin-left: auto;
}
.widget-content .widget-numbers {
    font-weight: 600;
    font-size: 11px;
    display: block;
}
.text-success {
    color: #3ac47d!important;
}
.widget-content .widget-content-outer .progress-sub-label {
    margin-top: .33333rem;
    opacity: 1;
    display: flex;
    align-content: center;
    align-items: center;
}
.widget-content .widget-content-outer .progress-sub-label .sub-label-right {
    margin-left: auto;
}
.react-time-picker {
    padding: 10px;
}

.react-time-picker__wrapper {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

}
