.contactcontainer1{
    background:url("../dashboard/assets/image/Contact_back.png") center ;
    background-repeat: repeat;
    width:auto;
    height:574px;
    background-size:cover;
}
.contactcontainer2{
    background-color: #f7f7f7;
    height:1200px;
   
}
.contactrow1{
    padding-top: 30vh;
    padding-left: 80vh;
}
.contactrow2{
    margin-top:0vh;
    padding-top:5vh;
}
.contactspam1{
    font-size: 50px;
    color:#fff;
}
.contactspam2{
    font-size:38px;
    color:#1E295D;
  
}
.contactspam3{
    color:#1E295D;
}
/* input[type=text] {
    box-sizing: border-box;
    border: 1px solid #D1108C;
    border-radius: 4px;
  }
  input[type=email] {
    box-sizing: border-box;
    border: 1px solid #D1108C;
    border-radius: 4px;
  } */
  .contactrow3{
    margin-top:6vh;
  }
  .contactspam4{
    font-size:38px;
    color:#1E295D;
  }

  .contactrow4{
      
    margin-top: 8vh;
  }
  .contactrow6{
    height:400px;
    margin-top:1vh;
}
.contactspan5{
    font-size:18px;
    color:#1E295D;
}
.contactcol5{
    background-image: url("../dashboard/assets/image/mumbaiback.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 300px;
    padding:20px;
    margin-right: 3vh;
    text-align: left;
 
    
}
.contactcol6{
    background-image: url("../dashboard/assets/image/noida.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 349px;
    padding:20px;
    margin-right: 3vh;
    text-align: left;
}
.contactcol7{
    background-image: url("../dashboard/assets/image/Bangalore.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 334px;
    padding:20px;
    margin-right: 3vh;
    text-align: left;    
}
.contactcol8{
    background-image: url("../dashboard/assets/image/delhi.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 334px;
    padding:20px;
    margin-right: 3vh;
    text-align: left;    
}


.contactrow5{
    margin-top:3vh;
}


@media only screen and (max-width: 767px) {
    .contactcontainer1{
        background:url("../dashboard/assets/image/Contact_back.png") center ;
        background-repeat: repeat;
        width:auto;
        height:228px;
        background-size:cover;
    }
    .contactspam2{
        font-size:28px;
        color:#1E295D;
      
    }
    .contactspam4{
        font-size:19px;
        color:#1E295D;
      }
      .contactspan4{
          font-size: 12px;
      }
      .contactspan5{
        font-size:12px;
        color:#1E295D;
    }
      .contactrow6{
        height:580px;
        margin-top:1vh;
    }
    .contactcol5{
        background-image: url("../dashboard/assets/image/mumbaiback.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 254px;
        padding:10px;
        margin-right: 8vh;
        margin-bottom: 4vh;
        text-align: left;
    }
    .contactcol6{
        background-image: url("../dashboard/assets/image/noida.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 254px;
        padding:10px;
        
      
        text-align: left;
    }
    .contactcol7{
        background-image: url("../dashboard/assets/image/Bangalore.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 254px;
        padding:10px;
        text-align: left;    
    }
    .contactcol8{
        background-image: url("../dashboard/assets/image/delhi.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 254px;
        padding:10px;
        margin-bottom: 4vh;
        text-align: left;    
    }
    .contactcontainer2{
        background-color: #f7f7f7;
       
    }
    .contactcontainer2{
        background-color: #f7f7f7;
        height:1370px;
       
    }
}



