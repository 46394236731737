@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

html{
    height: 100%;
  }
  body {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.5;
    color: #1e295d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f3f3ff!important;
   
}


  *{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff!important;
    background-clip: initial;
    border: 1px solid rgba(32,39,140,.125);
    border-radius: .25rem;
}
.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8,10,37,.03), 0 0.9375rem 1.40625rem rgba(8,10,37,.03), 0 0.25rem 0.53125rem rgba(8,10,37,.05), 0 0.125rem 0.1875rem rgba(8,10,37,.03);
  border-width: 0;
  transition: all .2s;
}
.card.mb-3 {
  margin-bottom: 30px!important;
}
.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: .625rem;
  height: 3.5rem;
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff!important;
  border-bottom: 1px solid rgba(32,39,140,.125);
}

.card-header.card-header-tab .card-header-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.card-header, .card-title {
  text-transform: uppercase;
  color: rgba(18,21,78,.7);
  font-weight: 700;
  font-size: .88rem;
}
.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: #fff!important;
  border-top: 1px solid rgba(32,39,140,.125);
}
.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.DateInput {
  position: static !important;
}

.DateInput_input {
  font-weight: 200 !important;
  font-size: 15px !important;
  line-height: 20px!important;
}
.DateRangePickerInput {
  background-color: #fff !important;
  display: inline-block !important;
  height: 0px !important;
  border-radius: 0px !important;
  border: 0px solid #dbdbdb !important;
}

.breadcrumbs {
  display: block;
  width: 100%;
  margin: 0 auto 10px;
}

.breadcrumbs div a {
  color: #1E295D;
  text-decoration: none;
}

.breadcrumbs div {
  display: inline-block;
}

.select__control {
  border: 1px solid #10204B !important;
 padding: 3px;
  border-radius: 6px !important;

}

