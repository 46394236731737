table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #10204B;
  text-align: center;

}

table td, table th {
  border: 0.1px solid #7E859A;
  padding: 6px;
  font-size: 12px;
}

table tr:nth-child(even){background-color:transparent;}

table tr:hover {background-color:rgb(252, 249, 249);}

table th, tfoot {
  padding:6px;
  font-size: 12px;
  text-align: center;
  background-color: #10204B;
  color: #fff;
}

select {
  word-wrap: normal;
  padding: 4px;
}

.customtable{
max-width: 100%; overflow-x: auto;
}

.loader{
  padding-left: 10px;
  padding-top: 10px;
}
.SearchFilter{
  text-align: left;
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  letter-spacing: 0px !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  background-color: transparent !important;
  
}
.columnsBtn{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  background-color: transparent;
  color: #10204B !important;
}

.columnsBtn:hover{
  background-color: #64646e20 !important;
 
}

.containerAme{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  background-color: transparent;
  color: #10204B !important;
}

.inputPage{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  background-color: transparent !important;
  text-align: center;
}
.tableHead{
  background-color: #f3f3ff;
  color: #10204B;
  font-weight: 600;
  text-align: center;
}

.table-bordered{
  background-color: #f3f3ff;
  color: #10204B;
  font-weight: 600;
  text-align: center;
}

#s_id{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  background-color: #E2E6F2;
}
#e_id{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  background-color: #E2E6F2;
}

#dateBtn:hover{
  background-color: #64646e20 !important;
  /* color: #fff; */
}

.collapse{
  height: auto;
  width: auto;
  float: center;
}
.previewImage{
  height: 200px;
  width: 200px;
  text-align: center;
}
.dateBtn{
  border: 1px solid #10204B !important;
  font: normal normal normal 14px/14px Helvetica !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  background-color: transparent;
  padding: 11px;
}