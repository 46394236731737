.app-page-title {
    padding: 10px;
    position: relative;
    background: hsla(0,0%,100%,.55);
}
.app-page-title .page-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.app-page-title .page-title-heading {
    font-size: 1.25rem;
    font-weight: 400;
    display: flex;
    align-content: center;
    align-items: center;
}
.app-page-title .page-title-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: .83333rem;
    margin: 0 30px 0 0;
   
    border-radius: .25rem;
    width: 60px;
    height: 60px;
}
.bg-mean-station {
    background-image: linear-gradient(120deg,#fccb90,#d57eeb)!important;
}

.app-page-title .page-title-subheading {
    padding: 3px 0 0;
    font-size: .88rem;
    opacity: .6;
}
.app-page-title .page-title-actions {
    margin-left: auto;
}
.form-signin .btn1 {
    font-size: 60%;
    border-radius: 0rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 0.3rem;
    transition: all 0.2s;
}

.avl{
    color:rgb(20, 110, 47);
  }
  .notavl{
    color:rgb(241, 124, 163);
  }

  .pink_text{
      color: #D1108C;
  }

.multiselect .name{
    font-size:30px;
}

.table.table td a
{
    color: #f8f9fa;
    display: inline-block;
    margin: 0 5px;
}

.hover{
    cursor: pointer;
}