.grey-bg {  
    background-color: #F5F7FA;
}
.bg-red{
    background-color: #e26429;
}

.btn-outline-primary {
    color: #1E295D !important;
    background-color: transparent;
    background-image: none;
    border-color: #1E295D !important;
}
.text-magenta{
    color: #1E295D !important;
}

.cardbg1{
    box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
    border-radius: .45rem;
    padding-top:5px;
    padding-bottom:5px;
}
/* .cardbg{
    box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
    border-radius: .45rem;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
} */
.topheader h3{
    color: #1E295D ;
    font-weight: 500;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 20px;
}
.stats{
    font-size: 16px;
    color:#1E295D;
}
.statsweight{
    font-weight: 700;
}
.rowpadding{
    margin-bottom:30px !important ;
}
.colpadding{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px;
    margin-right: 0px;
}
.charttitle{
font-size: 16px;
font-weight: 700;
color: #1E295D;
}
.fa-download{
    color:#1E295D;
}
.chart-area2{
    width: 100%;
    height: 300px;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
  }
  
  .bg-white {
    background-color: #fff!important;
    height: 100%;
}



/* New inslights css starts from here */


.topCardDesigns{
    /* background: linear-gradient(to right, deeppink, cyan); */
	background: #D7DEF2 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 1px #e9e9e9;
	border-radius: 14px;
	top: 126px;
	left: 280px;
	padding: 5px;
    
}
.singleCard{
   background-color: transparent !important;
   border: solid 0px !important;
}
.vl {
    border-left: 2px solid #BDBFC7 ;
    height: 70px;
    margin-top: 25px; 
    
}
.sideCardDesigns{
    /* background: linear-gradient(to right, deeppink, cyan); */
    background: #D7DEF2 no-repeat padding-box;
	box-shadow: 0px 1px 1px #e9e9e9;
	border-radius: 14px; 
    padding: 10px;
  
}
.subSideCardDesigns{
    /* background: linear-gradient(to right, deeppink, cyan); */
    background: #E4E8F3 no-repeat padding-box;
	box-shadow: 0px 1px 1px #e9e9e9;
	border-radius: 14px; 
    padding: 10px;
    opacity: 1;
  
}
.refresh:hover{ 
    cursor: pointer;
}
.insideHeading{
    color: #10204B !important;
    font-weight: 600 !important;
    font-size: 14px;
}
.insideContent{
    color: #bc0078 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.form-control{
    border: 1px solid #10204B !important;
    font: normal normal normal 14px/14px Helvetica !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    background-color: transparent !important; 
}
.formContainer{
    background:white no-repeat padding-box;
	box-shadow: 0px 1px 1px #e9e9e9;
	border-radius: 14px; 
    padding: 10px;
    /* margin: 20px; */

}

.formLable{
    text-align: left;
    font: normal normal normal 12px/12px Helvetica;
    letter-spacing: 0px;
    color: #10204B;
    opacity: 1;
}

.textColor{
    text-align: left;

    letter-spacing: 0px;
    color: #10204B;
}

.formHeading{
    color: #10204B;
}
.breadcrumb{
    font: normal normal normal 14px/14px Helvetica !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    background-color: white !important;
    color: #10204B !important;
}





/* .graphCard{
    background-color: #fff;

} */


