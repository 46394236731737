
.btn-actions-pane-right {
    margin-left: auto;
    white-space: nowrap;
}

.btn-outline-focus:hover {
    color: #fff;
    background-color: #444054;
    border-color: #444054;
}

.btn-outline-2x {
    border-width: 2px;
}
.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
  }
  .hr-sect::before,
  .hr-sect::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }
 .bg-custom-1 {
    background-color: #F5F5F5  !important;
  }
.cardbg {
  color: #1E295D;
}
.card-title{
  color: #1E295D;
  font-size: 13px;
}

.btn{
  border-radius: 8px !important;
}
.mr-1{
  font-size: 11px;
}