:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.loginText{
  font-size: 30px;
  font-weight: bold  !important;
  color: #1e295d;
 
}

.card-signin .card-body {

  padding: 2rem;
  
}

.form-signin {
  margin-top: 20px;
  margin-left: 70px;
  margin-right: 70px;
}

.form-signin .btn {

  border-radius: 5rem;
  letter-spacing: .1rem;
  padding: .7rem;
  transition: all 0.2s;
  background: #BC0078 !important;
  color: white !important;
  font-size: 18px !important;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
select {
  height: auto;
  border-radius: 5px;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
  
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 0px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.loginLabel:after {
  content: "*";
  color: red;

}
.loginLabel{
  padding-left: 3px;
  color: #1e295d !important;
  text-align: left;
  font: normal normal normal 16px/18px Helvetica;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 500;
}


.form-label-group input::-webkit-input-placeholder {
  color: transparent;

}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
  
}

.form-label-group input::-moz-placeholder {
  color: transparent;
  
}

.form-label-group input::placeholder {
  color: transparent;
  
}

.form-label-group input:not(:placeholder-shown) {
  /* padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3); */
  color: #1e295d;
  
   
}

.form-label-group input:not(:placeholder-shown)~label {
  /* padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3); */
  font-size: 12px;
  color: #C6C6C6 !important;
  display: none;
  
  
}

.insideLable{
  text-align: left;
  font: normal normal normal 15px/15px Helvetica;
  letter-spacing: 1px;
  opacity: .5;
  font-weight: 550;
  
}



/* Fallback for Edge
  -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
    position: relative;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }

}

/* Fallback for IE
  -------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }

 
}

@media (max-width:400px) {
  .loginText{
    font-size: 18px !important;
    font-weight: bold  !important;
    color: #1e295d;
   
  }

} 

.border-radius {
  border-radius: 50px !important
}

.form-control {
  height: calc(2.25em + 0.70rem + 2px) !important;
}


.help-block {
  text-align: left;
  
}

.h-1000 {
  background: url("../dashboard/assets/image/backgroundimage.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-left: -2.5rem !important;
  background-color: #fff;
  min-height: 100vh;
}

.custom-control {
  padding-left: 0.2rem !important;
}


.sign-up-header h3 {
  text-align: center;
  padding: 23px;
  text-transform: uppercase;
  font-family: Raleway;
  color: #fff;
}

.sign-up-header h5 {
  text-align: center;
  padding: 10px;
  font-family: Raleway;
  color: #fff;
}

#outer-container {
  width: 500px;
  height: 595px;
  margin: 50px auto;
  background: #f7f7f7;
  position: relative;
  border-radius: 10px;
  background-color: white !important;
  box-shadow: 0 8px 20px 0 rgba(31, 38, 135, 0.37) !important;
  -webkit-backdrop-filter: blur(8.5px) !important;
  backdrop-filter: blur(8.5px) !important;
}



.logclass1 {
  font-size: 15px;
  color: #1e295d;

}

/* .forgot {
  position: absolute;
  top: 360px;
  left: 240px;
} */

.p-viewer {
	z-index: 9999;
	position: absolute;
	top: 15%;
	right: 10px;
}

.fa-eye {
	color: #000;
}
.forgotLable{
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 16px/16px Helvetica;
  letter-spacing: 0px;
  color: #10204B;
  

}
.forgotLable:hover{
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 16px/16px Helvetica;
  letter-spacing: 0px;
  color: #10204B;
  

}
.contactText{
  text-align: left;
  font: normal normal medium 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.contactText:hover{
  text-align: left;
  font: normal normal medium 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.contactContainer {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: transparent !important;

}
.form-control{
  background-color: #E5E1EA !important;
  border: 0px solid !important;


}
.footerContainer{

  position: fixed;         
  bottom: 0px; 
  text-align: left;
  font: normal normal normal 15px/15px Helvetica;
  letter-spacing: 0px;
  color: #D8D8D8;
  opacity: 1;
}
.otpBtn{
  border-radius: 5rem;
  letter-spacing: .1rem;
  padding: .7rem;
  transition: all 0.2s;
  background: #BC0078 !important;
  color: white !important;
  font-size: 18px !important;
  border: 0px solid transparent !important;
}