.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.btn-primary {
    color: #fff;
    background-color: #1e295d!important;
    border-color: #1e295d!important;
}
.btn-primary.btn-shadow:hover {
    box-shadow: 0 0.125rem 0.625rem #1e295d!important;
}
.btn.btn-wide {
    padding: .375rem 1.5rem;
    font-size: .8rem;
    line-height: 1.5;
    border-radius: .25rem;
}
.btn.btn-pill, .btn.btn-pill.btn-wide {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.font-size-lg {
    font-size: 1.1rem!important;
}
.font-weight-normal {
    font-weight: 400!important;
}
.text-capitalize {
    text-transform: capitalize!important;
}
.z-index-6 {
    z-index: 6;
}

.bg-transparent {
    background: transparent!important;
}
.no-shadow {
    box-shadow: 0 0 0 transparent!important;
}
.rm-border {
    border-width: 0!important;
}
.rounded-circle {
    border-radius: 50%!important;
}

.icon-wrapper {
    width: 54px;
    height: 54px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}
.icon-wrapper .icon-wrapper-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: .2;
}
.opacity-10 {
    opacity: 1!important;
}

.opacity-8 {
    opacity: .8!important;
}

.widget-chart.text-left {
    flex-direction: row;
    align-items: center;
    padding-left: 30%;
    position: relative
}
.widget-chart.text-left .icon-wrapper {
    min-width: 54px;
    margin: 0 1rem 0 0;
}
.icon-wrapper {
    width: 54px;
    height: 54px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-content: center;
    align-items: center;
}

.rounded-circle {
    border-radius: 50%!important;
}
.icon-wrapper .icon-wrapper-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: .2;
}
.icon-wrapper i {
    margin: 10px;
    font-size: 1.7rem;
    position: relative;
    z-index: 5;
    align-items: center;
    color: white;
}
.widget-chart.text-left .widget-chart-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1 1;
    z-index: 5;
}
.widget-chart .widget-subheading:first-child {
    margin-top: 0;
}
.widget-chart .widget-subheading {
    margin: -.5rem 0 0;
    display: block;
    opacity: .6;
}
.widget-chart.text-left .widget-numbers {
    margin-left: 0;
}
.widget-chart .widget-subheading+.widget-numbers {
    margin-top: .5rem;
}
.widget-chart .widget-numbers {
    font-weight: 700;
    font-size: 2.5rem;
    display: block;
    line-height: 1;
    margin: 1rem auto;
}
.widget-chart.text-left .widget-chart-content .widget-description {
    align-self: flex-start;
}
.widget-chart .widget-numbers+.widget-chart-flex, .widget-chart .widget-numbers+.widget-description, .widget-chart .widget-numbers+.widget-subheading {
    margin-top: -.5rem;
}
.widget-chart .widget-description {
    margin: 1rem 0 0;
}

.text-focus {
    color: #1e295d!important;
}

.table td, .table th {
     font-size: 12px;
}


.table-action {
    align-items: center;
    padding: 0px 2px;
}
.table-action i {
    margin-top: 5px;
    min-width: 12px;
}

 .edit {
   color: grey;
}
 .slash {
    color: red;
 }
 .active {
    color: green;
 }
 
.btn-primary-csv-download {
    color: #fff;
    background-color: #d7def2!important;
    border-color: #1e295d!important;
}
.btn-primary-csv-download:hover {
    background-color: #c9cfe2!important;
    box-shadow: 0 0.125rem 0.625rem #c9cfe2!important;
}