.insights{
content: '\f144';
font-family: FontAwesome;
}
.unordered-list .list-item :hover{
    /* -webkit-transform: scale(1);
	transform: scale(1); */
	/* -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    -webkit-transform: scale(1); */
	transform: scale(1.05);
}

.glassbackground{
	align-items: center;
	flex-direction: column;
	height: 100%;
	/* width: 30vw; */
	background: #1E295D;
	/* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
	backdrop-filter: blur(8.5px);
	-webkit-backdrop-filter: blur(8.5px);
	border-radius: 10px;
	color: #ffffff;
	width: 100%;
}
