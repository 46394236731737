* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.user-detail-container {
  width:100%;

}
.user-detail {
padding: 20px;
}
.user-detail .card {
  box-sizing: border-box;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
  border: 0;
  padding: 20px;
  margin-bottom: 20px;
}

.user-detail .card .card-body {
  padding: 0;
}
.user-detail .card .card-img-top {
  width: 100%;
  text-align: center;
}

.user-detail .card .card-img-top img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.user-detail .user-profile-card {
  text-align: center;
}

.parent-container {
  padding: 0 0 0 20px;
  width: 100%;
}

.user-detail .user-profile-card h3 {
  margin: 5px 0;
}

.user-detail .user-detail-card span {
  font-size: 18px;
  color: var(--primary);
  font-weight: 300;
}

.table-wrap {
height: 500px;
overflow-y: auto;
}
