
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.wrapper{
  margin: 0px;
}

.wrapper .toolbar{
  width: 100%;
  height: 55px;
  top:0;
  left: 0;
  position: fixed;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  
 
}
.wrapper .toolbar .toolbar_navigation{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.wrapper .toolbar .toolbar_navigation .toggle-button{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  width: 25px;
  background:transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  padding-left: 0px;
}

@media (max-width: 576px) {
  .wrapper .toolbar .toolbar_navigation .toggle-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 15px;
    width: 15px;
    background:transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    padding-left: 5px;
  } 

}

.wrapper .toolbar .toolbar_navigation .toggle-button:focus{
  outline: none;
}

.wrapper .toolbar .toolbar_navigation .toggle-button_line{
  width: 20px;
  height: 2px;
  background-color: #d1108c;
}
.fa-cog, .fa-user-tie, .fa-sign-out-alt{
  color:#d1108c;
}


.wrapper .toolbar .toolbar_navigation .toolbar_logo{
  margin-left: 0.5rem;
  margin-right: 20px;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}
.wrapper .toolbar .toolbar_navigation .toolbar_logo .logo {
  height: 70px;

}
.wrapper .toolbar .toolbar_navigation .toolbar_logo a{
  font-size: 1.0rem;
  color: #1E295D;
  text-decoration: none;
  font-weight: 700;
}

.wrapper .toolbar .toolbar_navigation .spacer{
  flex: 1;
}
.wrapper .toolbar .toolbar_navigation .toolbar_navigation_items ul {
  padding-top:15px ;
  display: flex;
 
}

.wrapper .toolbar .toolbar_navigation .toolbar_navigation_items li {
  display: block;
  top: 10px;
  margin: 13px;
  
  height: 20px;
  text-align: center;
}

.wrapper .toolbar .toolbar_navigation .toolbar_navigation_items li img{
height:29px;
width:29px;

}
.wrapper .toolbar .toolbar_navigation .toolbar_navigation_items  ul li :hover{
  cursor:pointer;
}


.wrapper .toolbar .toolbar_navigation .toolbar_navigation_items ul li :hover i{
  color: #fff;
}

.wrapper .sidebar_wrapper{
  height: -webkit-fill-available;
  background: #1E295D;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 46px;
  left:0;
  width: 235px;
	z-index: 99;
  transition: transform 0.3s ease-in-out;
  overflow: auto;
}

/* .wrapper .sidebar_wrapper ul li a{
  display: block;
  padding: 20px;
  position: relative;
  
} */
.wrapper .sidebar_wrapper ul {
  margin: 0;
  padding: 0;

}
.wrapper .sidebar_wrapper ul li {
  padding: 12px 12px;
  /* border-bottom: 3px solid #Adacac;
  border-bottom-left-radius: 20px; */
  position: relative;
  text-align: left;
}

.wrapper .sidebar_wrapper li {
 position: relative;
 list-style: none;
 transition: all 0.4s ease;
}

.wrapper .sidebar_wrapper li:hover  {
  background: #585196;
 }

.navname{
  color: #1E295D;
}
 

/* .wrapper .sidebar_wrapper ul li.active:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
} */

.wrapper .sidebar_wrapper ul li a {
  color: #1e295d;
  display: block;
  text-decoration: none;
}

.wrapper .sidebar_wrapper ul li .menu-icon {
  /* padding-right: 15px; */
  margin-left: 0px !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

.wrapper .sidebar_wrapper ul li.showMenuOpts span {
transform: rotate(-180deg);
}

.wrapper .sidebar_wrapper ul li .sub-menu {
  padding: 5px 0px 1px 55px;
  margin-top: 0px;
  /* background: #393375; */
  display: none;
}

.wrapper .sidebar_wrapper ul li.showMenuOpts .sub-menu {
  display: block;
}

.wrapper .sidebar_wrapper ul li .sub-menu li {
  padding: 0;
}

.wrapper .sidebar_wrapper ul li .sub-menu a {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
  white-space:nowrap;
  opacity: 0.6;
  transition: all 0.3 ease;
}

.wrapper .sidebar_wrapper ul li .sub-menu a:hover {
  opacity: 1;
}

.wrapper .sidebar_wrapper ul li i {
  color: #d1108c;
  min-width: 20px;
  text-align: center;
}
.wrapper .sidebar_wrapper ul li span:nth-child(2) {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
}

.wrapper .main_container{
  margin-top: 70px;
  margin-left: 230px;
  transition: all 0.3s ease;
  
}

.wrapper .main_container .item{
  background: #fff;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 14px;
  line-height: 22px;
 
}


.wrapper.toggled .sidebar_wrapper { 
  width: auto;
  overflow-y: clip;
  overflow: visible;
}

.wrapper.toggled .main_container {
   margin-left: 45px;
}

.wrapper.toggled .sidebar_wrapper ul li a{
  text-align: center; 
}

.wrapper.toggled .sidebar_wrapper ul li a span.icon{
  margin: 0;
}

.wrapper.toggled .sidebar_wrapper ul li span.menu-icon{
  display: none;
}

.wrapper.toggled .sidebar_wrapper ul li a span.title{
  display: none;
}

.wrapper.toggled .sidebar_wrapper ul li .sub-menu {
  position: absolute;
  left: 100%;
  top:-10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  transition: all 0.2s ease;
  opacity: 0;
  background: #585196;
  display: block;
  pointer-events: none;
}

.wrapper.toggled .sidebar_wrapper ul li:hover .sub-menu {
top: 0;
opacity: 1;
pointer-events: auto;
}

.wrapper.toggled .sidebar_wrapper ul li .sub-menu .sm-title {
  font-size: 12px;
  font-weight: 700;
  opacity: 1;
  display: block;
}


.wrapper .sidebar_wrapper .copyright {
  position: fixed;
  bottom: 0;
  margin-left: 10px;
    font-size: 12px;
    font-weight: 700;
    z-index: -10;
}

.breadcrumbs{
  font-size: 13px;
}


.fa-user-check{
  color: green;
}

.fa-user-slash{
  color: red
}

