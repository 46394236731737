
   .pin{
    content:url("../insights/mapic.png");
    width: 23px;
    height: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -15px;
    
   }
   .pin-tsr{
    content:url("../insights/logo.png");
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -15px;
    
   }
  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  
  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    margin: -10px 0 0 -10px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(0deg);
    }
  
    30% {
      opacity: 1;
      transform: translateY(30px) rotate(0deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(0deg);
    }
  
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }