.custom-card {
    background: #d7def2 0 0 no-repeat padding-box !important;
    margin: 7px !important;
    border-radius: 12px !important;
    border: none !important
}

.card_title {
    font-size: 12.5px;
    font-weight: 790;
    color: #1E295D;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.custom-vl {
    border-left: 1.5px solid #BDBFC7;
    height: 85px;
    margin-top: 6px;
}

.min-width-div {
    min-width: 65px;
}

.min-width-div-busy {
    min-width: 40px;
}

.changedValue {
    background-image: repeating-linear-gradient(45deg, rgb(0, 0, 255), yellow, black);
    background-size: 800% 800%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow 4s ease infinite;
    transition: all 3.s ease-in-out;
    transform: scale(2);
}

@keyframes rainbow {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 25%
    }

    100% {
        background-position: 0% 50%
    }
}

#root:fullscreen {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: inherit !important;
    overflow-y: scroll !important
}