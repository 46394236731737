.InovuaReactDataGrid__body{
    font-size: 12px !important;
}

.InovuaReactDataGrid__row-cell-wrap .InovuaReactDataGrid__row-hover-target{
    /* height: 30px !important; */
    color: #10204B !important;
    font-size: 12px;
    border: 0.1px solid #7E859A;
}



.InovuaReactDataGrid__cell {height: 10px;
}