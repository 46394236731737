.z-999 {
    z-index: 999;
  }
  .primary-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.375rem 0;
    overflow-x: hidden;
  }
  .primary-navigation>div {
    width: 95%;
    max-width: 1190px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
  
  .primary-navigation a {
    color: #213757;
  }
  .c-logo {
    height: 20px;
     margin-right: 0px;
  }

  .logo {
    height: 80px;
    /* width: 120px; */
    margin-top: 10px;
    margin-left: 0px;
}

.logo-tsr {
  height: 90px;
  /* width: 120px; */
  margin-top: 10px;
  margin-left: 0px;
}

.logo-tsr-ds {
  height: 50px;
  width: 50px;
  margin-top: 5px;
  margin-left: 0px;
}

  .primary-navigation nav ul {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: baseline;
  }
  .primary-navigation nav li, .primary-navigation nav ul {
    margin: 0px;
    padding: 5px;
  }
  .primary-navigation nav li {
    display: -webkit-flex;
    display: flex;
    flex-grow: 1;
    list-style: none;
    padding-top: 5px;
  }
  
  .primary-navigation .hollow-button {
    border: 1px solid;
    border-radius: 6px;
    margin-left: 1.25rem;
    padding-right: 10px;
    padding-left: 10px;
  }
  .primary-navigation nav a {
    font-size: 15px;
    font-weight: 600;
    flex-grow: 1;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: all .2s ease;
    transform: scale(1);
    display: block;
    padding: 6px 0;
    white-space: nowrap;
  }
  .primary-navigation a {
    color: #fff;
    font-weight: 900;
  }
  .mh1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  .hollow-button-container {
    border: 1px solid;
    border-radius: 6px;
    margin-left: 1.25rem;
    padding-right: 10px;
    padding-left: 10px;
  }



@media (min-width: 768px) {
    
    .headline {
        padding:5%;
       align-self: center;
        font-family: Dosis, sans-serif;
        color: #213757;
        font-size: 60px;
        line-height: 120%;
        font-weight: 600;
        text-align: center;
        text-transform: none;
    }
    .headline-ev {
        padding:5%;
        align-self: center;
        font-family: Dosis, sans-serif;
        color: #213757;
        font-size: 35px;
        line-height: 120%;
        font-weight: 600;
        text-align: center;
        text-transform: none;
    }

.intro_hero_p {
    width: 80%;
    margin-top: 15px;
    margin-bottom: 20px;
    font-family: proxima-nova, sans-serif;
    color: #213757;
    font-size: 24px;
    line-height: 120%;
    font-weight: 400;
    text-align: center;
    text-transform: none;
}
}

@media (max-width: 576px) {
    .wrapper .main_container{
        margin-top: 70px;
        margin-left: 0px;
        transition: all 0.3s ease;
        
      }
    .headline {
        font-family: Dosis, sans-serif;
        color: #213757;
        font-weight: 400;
        text-transform: none;
        font-size: 25px;
        justify-content: space-between;
        align-items: center;
    }
    .headline-ev {
        font-family: Dosis, sans-serif;
        color: #213757;
        font-weight: 300;
        text-transform: none;
        font-size: 15px;
    }

.intro_hero_p {
     margin-top: 15px;
    margin-bottom: 20px;
    font-family: proxima-nova, sans-serif;
    color: #213757;
    font-weight: 300;
    text-transform: none;
    font-size: 12px;
}
.c-logo {
    display: inline-flex;
    align-items:left;
    font-weight: 600;
    color: #213757;
    line-height: 1.5rem;
    text-decoration: none;
    font-size: 12px;
    border-bottom: none;
  }
}

.masthead {
  height: 100vh;
  /* min-height: 500px;
  background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}


strong { font-weight: 600; }

.footer-top  a { color: #856dc0; border-bottom: 1px dashed #856dc0; text-decoration: none; transition: all .3s; }
.footer-top a:hover, a:focus { color: #856dc0; border: 0; text-decoration: none; }

.footer-top h1, h2 { margin-top: 10px; font-size: 38px; font-weight: 300; color: #555; line-height: 50px; font-style: italic; }
.footer-top h3 { font-size: 22px; font-weight: 300; color: #555; line-height: 30px; font-style: italic; }

img { max-width: 100%; }

.medium-paragraph { font-size: 17px; line-height: 32px; }

::-moz-selection { background: #7762ac; color: #fff; text-shadow: none; }
::selection { background: #7762ac; color: #fff; text-shadow: none; }

/***** Footer *****/

.footer-top { padding: 60px 0 40px 0; text-align: left; }
.footer-top h3 { padding-bottom: 10px; }

.footer-contact p { word-wrap: break-word; }
.footer-contact i { padding-right: 10px; font-size: 18px; color: #ddd; }
.footer-contact p a { border-bottom: 1px dashed #ccc; }
.footer-contact p a:hover, .footer-contact p a:focus { border-bottom: 1px dashed #856dc0; }

.footer-links a { color: #888; border: 0; }
.footer-links a:hover, .footer-links a:focus { color: #555; margin-left: 3px; }

.footer-bottom { padding: 0 0 60px 0; }

.footer-social a { margin: 0 10px; color: #bbb; border: 0; }
.footer-social a:hover, .footer-social a:focus { color: #856dc0; border: 0; }
.footer-social i { font-size: 24px; vertical-align: middle; }

.custom-control-1{
margin: 1rem;
}