.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  
  width: 100%;
  text-align: center;
  
}

::-webkit-input-placeholder { /* WebKit browsers */
  color:    black;
   opacity: 1 !important;
}